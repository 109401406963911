import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../../../styles/styleConstants';
import Utils from '../../../../../utils/utils';
import ModalWrapper, {ModalContentWrapper, ModalLoader} from '../../../../../pieces/modals/modalWrapper';
import {useForm, FormProvider} from 'react-hook-form';
import clsx from 'clsx';
import {globalUseClasses} from '../../../../../styles/globalClasses';
import {Grid, Box, Typography, Switch, FormControlLabel} from '@mui/material';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../../../pieces/inputs/formInput';
import FormSubmitButtons from '../../../../../pieces/formSubmitButtons';
import * as Yup from 'yup';
import validator from '../../../../../utils/validators/validator';
import {yupResolver} from '@hookform/resolvers/yup';
import base_url from '../../../../../baseUrls';
import {FormLabel, FormValue} from '../../../../../pieces/displayJob/formLine';
import {Text} from '../../../../../pieces/text';
import Spacer from '../../../../../pieces/spacer';
import {useJob} from '../../../../../contextProviders/jobContext';

export const ScheduleInspectionModal = (props)=> {
  const {INSPECTION_FIELDS, SCHEDULING_FIELDS} = allConstants;

  const jobContext = useJob();

  const [ipermitRater, setIpermitRater] = useState(jobContext.inspectionWithOwnRater());



  Yup.addMethod(Yup.string, 'validateEmails', function (errorMessage) {
    return this.test('email', errorMessage, function (value) {
      const {path, createError} = this;
      return (
        validator.validateMultipleEmails(value, true) ||
        createError({path, message: errorMessage})
      );
    });
  });

  Yup.addMethod(Yup.string, 'isValidPhoneNumber', function (errorMessage) {
    return this.test('phone_number', errorMessage, function (value) {
      const {path, createError} = this;
      return (
        validator.isValidPhoneNumber(value) ||
        createError({path, message: errorMessage})
      );
    });
  });

  const validationSchema = Yup.object().shape({
    [SCHEDULING_FIELDS.START_DATE.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
    [SCHEDULING_FIELDS.NOTIFY_HO.api_name]: Yup.object().shape({
      [allConstants.USER_FIELDS.EMAIL.api_name]: Yup.string().ensure()
        .when(SCHEDULING_FIELDS.NOTIFY_HO_EMAIL.api_name, {
          is: (value)=> {
            return !!value;
          },
          then: Yup.string().validateEmails(allConstants.ERROR_MSG.INVALID_EMAILS).nullable().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
        }),

      [allConstants.USER_FIELDS.PHONE_NUMBER.api_name]: Yup.string().ensure()
        .when(SCHEDULING_FIELDS.NOTIFY_HO_PHONE.api_name, {
          is: (value)=> {
            return !!value;
          },
          then: Yup.string().isValidPhoneNumber(allConstants.ERROR_MSG.INVALID_PHONE).nullable().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
        })
    }),
    [SCHEDULING_FIELDS.START_TIME.api_name]: Yup.string().when('ipermitRater', {
      is: true,
      then: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
    }),
    [SCHEDULING_FIELDS.RATER_NAME.api_name]: Yup.string().when('ipermitRater', {
      is: true,
      then: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
    })

  });

  const formOptions = {resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
    defaultValue: {
      [SCHEDULING_FIELDS.START_TIME.api_name]: '',
    }
  };

  const formMethods = useForm(formOptions);

  useEffect(()=> {
    formMethods.setValue('ipermitRater', ipermitRater);
    const errors = formMethods.formState.errors;
    if(!ipermitRater && errors && errors[SCHEDULING_FIELDS.START_TIME.api_name]){
      formMethods.trigger([SCHEDULING_FIELDS.START_TIME.api_name]);
    }
  }, [ipermitRater]);

  if(Utils.isDevEnv()) {
    window.schedulingForm = formMethods;
  }

  const classes = {...globalUseClasses()};


  const notifyByEmailFieldName = `${SCHEDULING_FIELDS.NOTIFY_HO.api_name}.${SCHEDULING_FIELDS.NOTIFY_HO_EMAIL.api_name}`;
  const notifyByPhoneFieldName = `${SCHEDULING_FIELDS.NOTIFY_HO.api_name}.${SCHEDULING_FIELDS.NOTIFY_HO_PHONE.api_name}`;

  const notifyByEmail = formMethods.watch(notifyByEmailFieldName);
  const notifyByPhone = formMethods.watch(notifyByPhoneFieldName);


  useEffect(()=> {
    if(!notifyByEmail) {
      formMethods.setValue(`${SCHEDULING_FIELDS.NOTIFY_HO.api_name}.${allConstants.JOB_FIELDS.GENERAL.HO_EMAIL.api_name}`, '',
        {shouldValidate: true});
    } else {
      formMethods.setValue(`${SCHEDULING_FIELDS.NOTIFY_HO.api_name}.${allConstants.JOB_FIELDS.GENERAL.HO_EMAIL.api_name}`, props?.homeOwnerInfo?.[allConstants.JOB_FIELDS.GENERAL.HO_EMAIL.api_name]);
    }
  }, [notifyByEmail]);

  useEffect(()=> {
    if(!notifyByPhone) {
      formMethods.setValue(`${SCHEDULING_FIELDS.NOTIFY_HO.api_name}.${allConstants.JOB_FIELDS.GENERAL.HO_PHONE.api_name}`, '',
        {shouldValidate: true});
    } else {
      formMethods.setValue(`${SCHEDULING_FIELDS.NOTIFY_HO.api_name}.${allConstants.JOB_FIELDS.GENERAL.HO_PHONE.api_name}`, props?.homeOwnerInfo?.[allConstants.JOB_FIELDS.GENERAL.HO_PHONE.api_name]);
    }
  }, [notifyByPhone]);

  const onSubmit = (data)=> {
    const notify = data[SCHEDULING_FIELDS.NOTIFY_HO.api_name];
    if(notify && notify[SCHEDULING_FIELDS.NOTIFY_HO_EMAIL.api_name]) {
      data[allConstants.JOB_FIELDS.GENERAL.HO_EMAIL.api_name] = notify[allConstants.JOB_FIELDS.GENERAL.HO_EMAIL.api_name];
    }
    if(notify && notify[SCHEDULING_FIELDS.NOTIFY_HO_PHONE.api_name]) {
      data[allConstants.JOB_FIELDS.GENERAL.HO_PHONE.api_name] = notify[allConstants.JOB_FIELDS.GENERAL.HO_PHONE.api_name];
    }

    delete data?.[SCHEDULING_FIELDS.NOTIFY_HO.api_name];

    const dataToSubmit = {...data, ...props.inspection};

    const raterFields = [
      SCHEDULING_FIELDS.RATER_NAME,
      SCHEDULING_FIELDS.RATER_ID,
      SCHEDULING_FIELDS.COLLECT_AMOUNT,
      SCHEDULING_FIELDS.SCHEDULER_NOTES,
      {api_name: 'ipermitRater'}
    ];

    if(!ipermitRater) {
      raterFields.forEach((field)=> {
        delete dataToSubmit[field.api_name];
      });
    }

    if(props.action) {
      props.action(dataToSubmit);
    }
  };

  const formInputDefaultProps = {
    viewMode: false,
    renderLabel: true,
    isEditable: true,
  };

  const notifyBlockStyles = {
    display: 'grid',
    gridTemplateColumns: '170px 1fr',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      '& .MuiTypography-root': {
        color: 'inherit'
      },
    }
  };
  const actionName = props?.inspection?.isRescheduling ? 'Rescheduled' : 'Scheduled';

  const isWithOwnRater = ()=> {
    if(!props.inspection.isRescheduling) return false;
    return jobContext.inspectionWithOwnRater();
  };

  const currentDataBlock = (label, value, valueInANewLine)=> {
    return (
      <>
        <Grid item xs={12} className={clsx(classes.general_col)} display={'flex'}>
          <FormLabel label={label}/>
          <Spacer x={1}/>
          {!valueInANewLine &&<FormValue value={value || ''}/>}
        </Grid>
        {valueInANewLine && <Grid item xs={12} className={clsx(classes.general_col)} display={'flex'} paddingTop={'8px'}
          sx={{
            '&.MuiGrid-item': {
              paddingTop: '4px'
            }
          }}
        >
          <FormValue value={value || ''}/>
        </Grid>}
      </>


    );
  };

  const reformatNoValueIfEmpty = (value)=> {
    return value || '-';
  };

  return (
    <ModalWrapper handleClose={props.handleClose}
      open={true}
      modalName={'schedule-inspection'}
      preventScroll={false}
      width={{sx: '98%', sm: '900px', md: '900px', lg: '1200px', xl: '900px'}}
      padding={4}
      customStyle={{paddingTop: 2, height: '95%'}}
    >
      <ModalContentWrapper handleClose={props.handleClose}
        header={`${props.inspection.type} ${actionName}`}
        note={isWithOwnRater() && !props.isFetching ? {
          text:  'When rescheduling fill all fields you need for the inspection. If empty - we will remove this data.',
          color: STYLE_CONSTANTS.COLORS.globalRed,
        } : null}
        toggler={ <FormControlLabel control={<Switch
          defaultChecked={isWithOwnRater()}
          onChange={(e)=> {
            setIpermitRater(e.target.checked);
          }}
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: STYLE_CONSTANTS.COLORS.globalGreen,
              '&:hover': {
                backgroundColor: (STYLE_CONSTANTS.COLORS.globalGreen, 'unset'),
              },
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: STYLE_CONSTANTS.COLORS.globalGreen,
            },
          }}
        />}
        label="with ipermit rater"
        sx={{
          textTransform: 'uppercase',
        }}

        />}
      >
        {props.isFetching && <ModalLoader/>}
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} height={'100%'}
        >
          {props?.inspection?.isRescheduling &&
          <>
            <Box sx={{
              minWidth:'300px',
              maxWidth: '300px',
              backgroundColor: STYLE_CONSTANTS.COLORS.lightBlueBackground,
            }}>
              <Box sx={{
                padding: {xs: '4px', sm: '8px'},
                backgroundColor: STYLE_CONSTANTS.COLORS.globalGreen,
              }}>
                <Text weight={'bold'} size={'xLarge'} text={'Current details'}/>
              </Box>


              <FormLabel component="legend">Inspection type</FormLabel>
              <Grid container spacing={2} sx={{
                padding: {xs: '4px', sm: '8px'},
              }}>
                {isWithOwnRater() && currentDataBlock('Rater', props.cityInspectionData?.rater?.name)}
                {currentDataBlock('Date',  Utils.formatIsoDateString(props.cityInspectionData?.[SCHEDULING_FIELDS.START_DATE.api_name], {date: true}))}
                {currentDataBlock('Start time', Utils.timeTo12HrFormat(props.cityInspectionData?.[SCHEDULING_FIELDS.START_TIME.api_name]))}
                {currentDataBlock('End time', Utils.timeTo12HrFormat(props.cityInspectionData?.[SCHEDULING_FIELDS.END_TIME.api_name]))}
                {currentDataBlock('Confirmation number', reformatNoValueIfEmpty(props?.cityInspectionData?.[INSPECTION_FIELDS.CONFIRMATION_NUMBER.api_name]))}
                {isWithOwnRater() && currentDataBlock('Collect Amount', props?.cityInspectionData?.[SCHEDULING_FIELDS.COLLECT_AMOUNT.api_name] ? `$${props?.cityInspectionData?.[SCHEDULING_FIELDS.COLLECT_AMOUNT.api_name]}` : '-')}
                {isWithOwnRater() && currentDataBlock('Scheduler notes', reformatNoValueIfEmpty(props?.cityInspectionData?.[SCHEDULING_FIELDS.SCHEDULER_NOTES.api_name]), true)}

              </Grid>
            </Box>
            <Spacer x={2}/>
          </>}
          <Box>

            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}  method="POST">
                <Grid container xs={12}>
                  {ipermitRater && <Grid item xs={12} sm={12} className={clsx(classes.general_col)}>
                    <CustomFormBlock
                      {...formInputDefaultProps}
                      field={{...SCHEDULING_FIELDS.RATER_NAME, required: true}}
                      inputType={inputTypes.autoComplete}
                      autocompleteParams={{
                        url: `${base_url.api}ipermit/raters/autocomplete`,
                        getRequestParams: (value)=> {
                          const params = {
                            [allConstants.RATER_FIELDS.DEPARTMENTS.api_name]: ['INSPECTION'],
                          };
                          if(value) {
                            params.searchString = value;
                          }
                          return params;
                        },
                        collectionName: 'data',
                        fill: [SCHEDULING_FIELDS.RATER_ID],
                      }}
                      fullWidth

                    />
                    <CustomFormBlock
                      {...formInputDefaultProps}
                      field={SCHEDULING_FIELDS.RATER_ID}
                      hide={true}
                    />
                  </Grid>}
                  <Grid item xs={12} sm={12} className={clsx(classes.general_col)} sx={{
                    width: '100%',
                    '.MuiButtonBase-root-MuiMenuItem-root': {
                      background: 'red',
                      textTransform:'uppercase !important'
                    }
                  }}>
                    <Box sx={{
                      display: 'grid',
                      gridTemplateColumns: '2fr 1fr 1fr',
                      gap: '16px',
                      alignItems: 'flex-start'
                    }}>
                      <CustomFormBlock
                        {...formInputDefaultProps}
                        inputType={inputTypes.date}
                        field={SCHEDULING_FIELDS.START_DATE}
                        fullWidth
                        preventCapitalize={true}
                      />
                      <CustomFormBlock
                        {...formInputDefaultProps}
                        inputType={inputTypes.time}
                        field={{...SCHEDULING_FIELDS.START_TIME, required: ipermitRater}}
                        fullWidth
                        preventCapitalize={true}
                      />
                      <CustomFormBlock
                        {...formInputDefaultProps}
                        inputType={inputTypes.time}
                        field={SCHEDULING_FIELDS.END_TIME}
                        fullWidth
                        preventCapitalize={true}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} >
                    <Box sx={{
                      display: 'grid',
                      gridTemplateColumns: ipermitRater ? '1fr 1fr' : '1fr',
                      gap: '16px',
                      alignItems: 'flex-start'
                    }}>
                      <CustomFormBlock
                        {...formInputDefaultProps}
                        field={INSPECTION_FIELDS.CONFIRMATION_NUMBER}
                        fullWidth
                      />
                      {ipermitRater &&  <CustomFormBlock
                        {...formInputDefaultProps}
                        field={SCHEDULING_FIELDS.COLLECT_AMOUNT}
                        inputType={inputTypes.currency}
                      />}
                    </Box>

                  </Grid>
                  {ipermitRater && <Grid item xs={12} sx={{
                    '& .MuiInputBase-inputMultiline': {
                      width: '100% !important'
                    }
                  }}>
                    <CustomFormBlock
                      {...formInputDefaultProps}
                      field={SCHEDULING_FIELDS.SCHEDULER_NOTES}
                      multiline={true}
                      fullWidth
                    />
                  </Grid>}
                  <Typography className={clsx(classes.card_section_header, classes.uppercase, classes.blue_text)}
                  >Notify homeowner</Typography>
                  <Grid item xs={12}>
                    <Box sx={notifyBlockStyles}>
                      <CustomFormBlock
                        {...formInputDefaultProps}
                        renderLabel={false}
                        labelLeft={true}
                        field={SCHEDULING_FIELDS.NOTIFY_HO_EMAIL}
                        inputType={inputTypes.checkbox}
                        defaultValue={false}
                        nested={true}
                        parent={SCHEDULING_FIELDS.NOTIFY_HO.api_name}
                      />
                      <CustomFormBlock
                        {...formInputDefaultProps}
                        field={allConstants.JOB_FIELDS.GENERAL.HO_EMAIL}
                        fullWidth
                        isEditable={notifyByEmail}
                        renderLabel={false}
                        nested={true}
                        parent={SCHEDULING_FIELDS.NOTIFY_HO.api_name}
                        defaultValue={notifyByEmail? props?.homeOwnerInfo?.[allConstants.JOB_FIELDS.GENERAL.HO_EMAIL.api_name] : ''}
                      />

                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={notifyBlockStyles}>
                      <CustomFormBlock
                        {...formInputDefaultProps}
                        renderLabel={false}
                        labelLeft={true}
                        field={SCHEDULING_FIELDS.NOTIFY_HO_PHONE}
                        inputType={inputTypes.checkbox}
                        defaultValue={false}
                        nested={true}
                        parent={SCHEDULING_FIELDS.NOTIFY_HO.api_name}
                      />
                      <CustomFormBlock
                        {...formInputDefaultProps}
                        inputType={inputTypes.phone}
                        field={allConstants.JOB_FIELDS.GENERAL.PHONE_NUMBER}
                        fullWidth
                        isEditable={notifyByPhone}
                        renderLabel={false}
                        nested={true}
                        parent={SCHEDULING_FIELDS.NOTIFY_HO.api_name}
                        defaultValue={notifyByPhone ? props?.homeOwnerInfo?.[allConstants.JOB_FIELDS.GENERAL.HO_PHONE.api_name] : ''}
                      />

                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'center'}>
                      <FormSubmitButtons cancelAction={props.handleClose} submitText={'Save'} cancelText={'Cancel'}
                        // disableSubmit={!canSubmit}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Box>
        </Box>
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

ScheduleInspectionModal.propTypes = {
  inspection: PropTypes.shape({
    isRescheduling: PropTypes.bool,
    type: PropTypes.string.isRequired
  }),
  handleClose: PropTypes.func.isRequired,
  homeOwnerInfo: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string
  }),
  action: PropTypes.func.isRequired,
  cityInspectionData: PropTypes.instanceOf(Object),
  isFetching: PropTypes.bool,
};

ScheduleInspectionModal.defaultProps = {
  cityInspectionData: {},
  isFetching: false,
};
