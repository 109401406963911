import React from 'react';
import PropTypes from 'prop-types';
import {Box, IconButton, Button} from '@mui/material';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

const variants = {
  textWithIcon: 'textWithIcon',
  icon: 'icon',
  text: 'text'
};
const AddRowButton = (props)=> {
  let button;
  if(props.variant === variants.textWithIcon) {
    button = <Button variant="contained" startIcon={ <AddIcon/>}
      onClick={props.addAction}
      sx={{
        borderRadius: '25px',
        textTransform: 'uppercase',
        fontSize: '12px',
        lineHeight: '14px',
        padding: '6px 10px'
      }}>

      {props.text ? props.text : 'Add Row'}
    </Button>;
  } else {
    button = <IconButton aria-label={`add-${props.eqName}`}
      color={'primary'}
      onClick={props.addAction}
      disableRipple
      sx={{paddingLeft: 0, transform: 'scale(1.3)'}}
    >
      <AddBoxRoundedIcon/>
    </IconButton>;
  }
  return(
    <Box sx={{marginTop: theme=> theme.spacing(2)}}>
      {button}
    </Box>
  );
};

const DeleteEquipmentRowButton = (props)=> {
  return(
    <Box sx={{
      ...props.extraStyles,
      position: 'relative',
      bottom: '8px'
    }}>
      <IconButton aria-label={`delete-${props.eqName}`}
        color={'error'}
        onClick={()=>props.deleteAction(props.index)}
        disableRipple
        disabled={props.index === 0}

        sx={{transform: 'scale(1.2)', '&.MuiIconButton-root': {padding: 0}}}
      >
        {props.index !== 0 && <DeleteForeverOutlinedIcon/>}


      </IconButton>
    </Box>
  );
};
AddRowButton.propTypes = {
  eqName: PropTypes.string.isRequired,
  addAction: PropTypes.func.isRequired,
  variant: PropTypes.string,
  text: PropTypes.string,

};

AddRowButton.defaultProps = {
  variant: variants.icon,
  text: null
};

DeleteEquipmentRowButton.propTypes = {
  eqName: PropTypes.string.isRequired,
  deleteAction: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  extraStyles: PropTypes.instanceOf(Object),
};

DeleteEquipmentRowButton.defaultProps = {
  extraStyles: {}
};


export {DeleteEquipmentRowButton, AddRowButton, variants};
